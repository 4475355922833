module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7f813a3535a540c8bd52c86310e2ea65@sentry.io/2979730","release":"0e33d3d17c1c91889032a216dc4d91abbcec0109","environment":"production","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-12K3ELHWE8"],"gtagConfig":{"optimize_id":"OPT-TNK4ZTD"},"pluginConfig":{"head":true,"exclude":["/public/"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Work Sans:400,700,900","Lato:400,700,900&display=swap","Nanum Pen Script:400&display=swap"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Meerkat","short_name":"Meerkat","start_url":"/","background_color":"white","theme_color":"white","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://beta.meerkat.co.za"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
